.ant-upload, .ant-upload-list-item {
    width: var(--img-crop-aspect-0) !important;
    height: var(--img-crop-aspect-1) !important;
}
.ant-upload-list-item-container{
    width: var(--img-crop-aspect-0) !important;
    height: var(--img-crop-aspect-1) !important;
}
.ant-upload-list.ant-upload-list-picture-card{
    gap: 24px !important;
}
.ant-upload-list-item-action{
    display :var(--display-anticon-delete-icon) !important;
}